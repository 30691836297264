import { API_URL_2, API_URL_3, OPEN_API_URL } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getLocalData } from "../../Utils";

const Base_URL = API_URL_2;
const openAPI = OPEN_API_URL;

export const GetClientDetailByEmailId = async (email) => {
  const encodedEmail = encodeURIComponent(email);
  try {
    return await ApiServices.get(
      `${openAPI}client/getClientDetailsByEmailId?email=${encodedEmail}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetGuestData = async (locationId) => {
  let staticLocationId = encodeURIComponent("LOC#1");
  try {
    return await ApiServices.get(
      `${openAPI}client/getGuestClient?currentLocationId=${staticLocationId}&newLocationId=${locationId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetAllPets = async () => {
  try {
    return await ApiServices.get(
      `${openAPI}schedule/getAllPets?clientId=${encodeURIComponent(
        getLocalData("clientId")
      )}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetAllClients = async () => {
  try {
    return await ApiServices.get(
      `${API_URL_2}client?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getClientProfileProgress = async () => {
  try {
    return await ApiServices.get(
      `${openAPI}client/getClientProfileProgress?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&clientId=${encodeURIComponent(getLocalData("clientId"))}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getPetProfileProgress = async (childId) => {
  const encodedchildId = encodeURIComponent(childId);

  try {
    return await ApiServices.get(
      `${API_URL_3}client/getChildProfileProgress?childId=${encodedchildId}&clientId=${encodeURIComponent(
        getLocalData("clientId")
      )}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const AddTeamMembers = async (obj) => {
  try {
    return await ApiServices.post(`${API_URL_3}client/addFamilyMember`, obj);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const searchTeamMembers = async () => {
  try {
    return await ApiServices.get(
      `${API_URL_2}client?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&status=1`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const getYourFamilyMembers = async () => {
  try {
    return await ApiServices.get(
      `${API_URL_3}client/getFamilyByClient?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&clientId=${encodeURIComponent(getLocalData("clientId"))}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const getFamilyPets = async () => {
  try {
    return await ApiServices.get(
      `${API_URL_3}client/getFamilyWithPet?locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}&clientId=${encodeURIComponent(getLocalData("clientId"))}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const getPetsTrophies = async (childId) => {
  const encodedPetId = encodeURIComponent(childId);
  try {
    return await ApiServices.get(
      `${API_URL_3}trophy/getTrophyByPet?clientId=${encodeURIComponent(
        getLocalData("clientId")
      )}&petId=${encodedPetId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getAllServices = async (categoryId, locationId) => {
  try {
    return await ApiServices.get(
      `${openAPI}schedule/getAllServices?locationId=${locationId}&categoryId=${categoryId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getFreeOrientation = async (clientId, locationId, clientName) => {
  try {
    return await ApiServices.get(
      `${API_URL_3}schedule/freePricingOption?locationId=${locationId}&clientId=${clientId}&clientName=${clientName}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getClientPurchase = async (clientId, orderId) => {
  try {
    return await ApiServices.get(
      `${API_URL_3}schedule/clientPurchase?clientId=${clientId}&orderId=${orderId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
