import axios from "axios";
import { clearLocalData } from "../Utils";

let instance = axios.create();

// Add a request interceptor to include the token in the headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const logout = () => {
  window.location.href = "/login";
  clearLocalData();
  window?.location?.reload();
};

// Function to handle token expiration and refresh failure
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the request failed due to 401 or 403 error
    if (
      error.response.status === 403 &&
      error.response.data.errorCode === "TOKEN_EXPIRED" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem("refreshToken");
      try {
        // Request a new token using the refresh token api
        const { data } = await axios.post(
          `${process.env.REACT_APP_OPEN_API_END_POINT}api/auth/refreshToken`,
          { refreshToken }
        );

        const { idToken, refreshToken: newRefreshToken } = data;

        localStorage.setItem("token", idToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        // Retry the original request with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${idToken}`;

        return axios(originalRequest);
      } catch (refreshError) {
        logout();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const ApiServices = {
  // Function to make a GET request
  get: async (url) => {
    try {
      const response = await instance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  // Function to make a POST request
  post: async (url, data) => {
    try {
      const response = await instance.post(url, data);
      return response.data;
    } catch (error) {
      // throw new Error(error.message);
      return error.response;
    }
  },

  // Function to make a PUT request
  put: async (url, data) => {
    try {
      const response = await instance.put(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  // Function to make a DELETE request
  delete: async (url) => {
    try {
      const response = await instance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};
