import { API_URL_3, API_URL_2, OPEN_API_URL } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getCurrentTimeZone, getLocalData } from "../../Utils";

const Base_URL = API_URL_3;
const openAPI = OPEN_API_URL;
const locationId = encodeURIComponent(getLocalData("locationId"));

export const GetAllExploreSchedules = async (payload) => {
  try {
    return await ApiServices.post(
      `${openAPI}schedule/getAppointments`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllAppointmentsDetail = async (payload) => {
  try {
    return await ApiServices.post(
      `${openAPI}schedule/getAppointmentDetails`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const SearchExploreSchedules = async (text) => {
  try {
    return await ApiServices.get(
      `${Base_URL}schedule/search?searchText=${text}&locationId=${encodeURIComponent(
        getLocalData("locationId")
      )}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetAllServiceCategories = async (serviceType) => {
  try {
    return await ApiServices.get(
      `${openAPI}schedule/getAllServiceCategories?locationId=${locationId}&serviceType=${serviceType}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetUpcomingAppointments = async (payload) => {
  try {
    return await ApiServices.post(`${openAPI}schedule/getAppointmentHistory`, {
      ...payload,
      timeZone: getCurrentTimeZone(),
    });
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetLocationBasedStates = async () => {
  try {
    return await ApiServices.get(
      `${openAPI}locations/LocationBaseStateAvailability?cwParam=true`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetLocationsList = async (state) => {
  if (state) {
    try {
      return await ApiServices.get(
        `${openAPI}locations?cwParam=true&state=${state}`
      );
    } catch (error) {
      console.error(error.message);
      return error;
    }
  }
};

export const AppointmentHistory = async (payload) => {
  let modifiedReq =
    payload?.status === "All"
      ? {
          clientParentId: payload?.clientParentId,
          locationId: payload?.locationId,
          type: payload?.type,
        }
      : { ...payload };

  try {
    return await ApiServices.post(`${openAPI}schedule/getAppointmentHistory`, {
      ...modifiedReq,
      timeZone: getCurrentTimeZone(),
    });
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllCreditAvailability = async (locationId, clientId) => {
  const requestObj = {
    locationId: locationId,
    clientId: clientId,
  };
  try {
    return await ApiServices.post(
      `${openAPI}checkout/getAllCreditAvailability`,
      requestObj
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
