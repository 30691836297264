import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, Slide, Typography, styled } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Backdrop = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 48, 135, 0.25)",
  zIndex: 1900,
});

export default function AdditionalDogPopup({
  open,
  setOpen,
  title,
  message,
  handleProceed,
  text,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {" "}
      {open && <Backdrop />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="signup_popup"
        sx={{ zIndex: 2000, padding: 1 }}
      >
        <DialogTitle className="alert-title text-center title signup_location_popup_label">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="signup_location_popup_label">
                {message}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          {text ? (
            <>
              <Button
                className="font-17 no-hover"
                onClick={() => handleProceed("yes")}
                sx={{
                  color: "#003087",
                  textTransform: "capitalize !important",
                  fontWeight: '700 !important'
                }}
              >
                Close
              </Button>
            </>
          ) : (
            <>
              <Button
                className="font-17 no-hover"
                onClick={() => handleProceed("yes")}
                sx={{
                  color: "#003087",
                  textTransform: "capitalize !important",
                  fontWeight: "700 !important",
                }}
              >
                Yes
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                className="font-17 no-hover"
                sx={{
                  color: "rgb(209, 58, 0)",
                  textTransform: "capitalize !important",
                  fontWeight: "700 !important",
                }}
                onClick={() => {
                  handleProceed("no");
                }}
              >
                No
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
