import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import loaderImg from "../../assets/images/loader.gif";
export function CustomLoader(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState("Loading...");

  useEffect(() => {
    setIsLoading(props.IsLoading);
    setLoaderMsg("Loading...");
  }, [props.IsLoading]);

  return (
    <Box>
      {isLoading ? (
        <div className="loader-container">
          <img src={loaderImg} className="loader-img" alt="loader" />
          <Box className="loader-msg">{loaderMsg}</Box>
        </div>
      ) : null}
    </Box>
  );
}
