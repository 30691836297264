import React from "react";
import {
  Checkbox,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { CustomButton } from "./CustomButton";

export const CustomInput = ({
  name,
  label,
  type,
  fullWidth,
  placeholder,
  showPassword,
  onTogglePassword,
  showCheckbox,
  maxLength,
  style,
  icon,
  required,
  clearSearchData,
  pasteCode,
  autoComplete,
  ...rest
}) => {
  return (
    <>
      {label && (
        <InputLabel>
          {label}
          {required && <span className="asterisk">*</span>}
        </InputLabel>
      )}
      <TextField
        variant="outlined"
        sx={{mt:{xs:2, sm:0}}}
        name={name}
        fullWidth={fullWidth}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete={autoComplete}
        type={type === "password" && showPassword ? "text" : type}
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      variant="outloned"
                      sx={{
                        background: "#E7EFF9",
                        padding: "3px",
                        marginX: 1,
                        borderRadius: 500,
                      }}
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={onTogglePassword}
                    >
                      {!showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : showCheckbox
            ? {
                endAdornment: (
                  <Checkbox
                    defaultChecked
                    disableTouchRipple
                    disabled
                    indeterminate
                    indeterminateIcon={<CheckCircleIcon />}
                    icon={<RadioButtonUncheckedIcon />}
                  />
                ),
              }
            : icon
            ? {
                endAdornment: <CustomButton
                className="arrow-btn"
                icon={icon}
                onClick={pasteCode ? pasteCode : clearSearchData && clearSearchData}
                clearSearchData={clearSearchData}
                // backgroundColor="#E7EFF9"
                
              />,
              }
            : {}
        }
        {...rest}
      />
    </>
  );
};
