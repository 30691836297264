import React, { useState, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CustomButton } from "../Common";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import { default as Earned } from "../../assets/images/earned.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { default as adjDogOne } from "../../assets/images/adjDogOne.svg";
import { LinearProgressBar } from "../Common";
import { CreateDogProfile } from "../../Services/APIs";
import Snackbar from "@mui/material/Snackbar";
import { ExploreContext } from "../../App";
import { default as backArrow } from "../../assets/images/orangeArrow.svg";
import RegisterPop from "../Common/RegisterPop";
const greyClosing = require("../../assets/images/greyClosing.svg").default;

export const CustomDialogue = ({
  open,
  handleClose,
  fullWidth,
  handleNext,
  type,
  className,
  data,
  reloadPage,
}) => {
  const { isGuest } = useContext(ExploreContext);
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    snackOpen: false,
    message: "Something Went wrong",
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, snackOpen, message } = state;
  const handleClick = (newState) => {
    setState({ ...newState, snackOpen: true });
  };
  const [openRegpopup, setOpenRegpopup] = useState(false);
  const handleSnackClose = (newState) => {
    setState({ ...state, snackOpen: false });
  };

  const handleDogCreation = () => {
    CreateDogProfile(data).then((response) => {
      if (response) {
        if (response.statusCode === 200) {
          reloadPage();
          handleClick({
            vertical: "top",
            horizontal: "right",
            message: "Dog Successfully added",
          });
        } else {
          handleClick({
            vertical: "top",
            horizontal: "right",
            message: "Something went wrong, Try again",
          });
        }
      }
    });
  };

  // Method to handle appointment button
  const handleAppointment = () => {
    if (isGuest) {
      setOpenRegpopup(true);
      handleClose();
    } else {
      navigate("/appointment");
      handleNext();
    }
  };

  const handleEnrollment = () => {
    navigate("/explore");
    handleNext();
  };

  return (
    <>
      <RegisterPop
        open={openRegpopup}
        setOpen={setOpenRegpopup}
        messege="In order to add this service, you will have to create your profile, Do you want to create now ?"
      />
      <Dialog
        fullWidth={fullWidth}
        maxWidth={fullWidth ? "md" : ""}
        open={open}
        onClose={handleClose}
        className={className}
      >
        {type === "profile" ? (
          <>
            <DialogContent>
              <Box className="checktext">
                <Typography>Complete Your Dog Profile</Typography>
                <Typography>
                  In order to book a service, you will have to complete your
                  profile. Do you want to complete it now?
                </Typography>
              </Box>
              <Box className="profileBtnWrap">
                <Button onClick={handleClose} className="confirmCheckBtn">
                  Skip
                </Button>
                <Button onClick={handleDogCreation} className="confirmCheckBtn">
                  Yes, I want
                </Button>
              </Box>
            </DialogContent>
          </>
        ) : type === "progressCheckout" || type === "progressAddToCart" ? (
          <>
            <DialogContent>
              <Box className="checktext">
                <Typography>Update Profile</Typography>
                <Typography>
                  {type === "progressAddToCart" ? "In order to add items to cart, you have to complete your profile first." : "In order to proceed for payment, you have to update your profile."}
                </Typography>
              </Box>
              <Box className="profileBtnWrap">
                <Button onClick={handleClose} className="confirmCheckBtn">
                  Skip
                </Button>
                <Button onClick={handleNext} className="confirmCheckBtn">
                  OK
                </Button>
              </Box>
            </DialogContent>
          </>
        ) : type === "appointment" ? (
          <Box className="mindDialog">
            <DialogActions className="dialog-actions">
              <CustomButton
                className="arrow-btn"
                icon={backArrow}
                backgroundColor="#E7EFF9"
                onClick={handleClose}
              />
              {/* <Typography>what do you have in mind?</Typography> */}
              <Typography className="heading-dialog" fontSize={{ xs: '14px' }}>what do you have in mind?</Typography>
            </DialogActions>

            <DialogContent>
              <Box className="schedule-section-wrap">
                <Box className="schedule-section-box">
                  <Box className="schedule-section-action f-21">
                    <CustomButton
                      onClick={handleEnrollment}
                      className="action-btn"
                      color="#E35205"
                      backgroundColor="#FFF"
                      title="Explore Schedule"
                    />
                    <Typography className="overlay-text">Orientations, Classes, Workshops, Events</Typography>
                  </Box>
                </Box>
                <Box className="schedule-section-box">
                  <Box className="schedule-section-action f-14">
                    <CustomButton
                      onClick={handleAppointment}
                      className="action-btn"
                      color="#003087"
                      backgroundColor="#FFF"
                      title="Book an Appointment"
                    />
                    <Typography className="overlay-text">
                      Evaluation, Private Gym, Private Training
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        ) : type === "earned" ? (
          <DialogContent>
            <Box className="modalCont">
              <Button onClick={handleClose}>
                <img src={greyClosing} alt="greyClosing" />{" "}
              </Button>
              <img src={Earned} alt="trophy" />
              <Box>
                <Typography>
                  Buck earned<span>Superdog</span>
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
              </Box>

              <CustomButton
                onClick={() => navigate("/appointment")}
                className="action-btn"
                color="#fff"
                backgroundColor="#003087"
                title="Share"
              />
            </Box>{" "}
          </DialogContent>
        ) : type === "nextUp" ? (
          <DialogContent>
            <Box className="modalCont">
              <Button onClick={handleClose}>
                <img src={greyClosing} alt="greyClosing" />{" "}
              </Button>
              <Box className="progresswrap">
                <CircularProgress
                  variant="determinate"
                  color="warning"
                  value={70}
                  className="circlePBar"
                />
                <img src={adjDogOne} alt="adjDogOne" className="adjDogImg" />
              </Box>
              <Box>
                <Typography>Superdog</Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
              </Box>
              <Box className="linearProgressWrap">
                <span>70%</span>
                <LinearProgressBar classes="modalProgress" value={70} />
              </Box>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent>
            <Box className="checktext">
              <Typography>Purchase Confirmation</Typography>
              <Typography>
                {/* If this booking consist any product, to receive this bundle you
                need to visit the store. */}
                If this purchase includes any products, you will need to visit the store to pick them up.
              </Typography>
            </Box>
            <Button onClick={handleNext} className="confirmCheckBtn">
              Confirm
            </Button>
          </DialogContent>
        )}
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};