import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

export const CustomButton = ({
  title,
  className,
  color,
  backgroundColor,
  fullWidth,
  onClick,
  width,
  icon,
  iconJsx,
  maxWidth,
  isLoading,
  fontWeight,
  fontSize,
  disabled,
  isCapitalize,
  clearSearchData,
  ...restProps
}) => {
  const buttonStyle = {
    color: color || "white",
    backgroundColor: backgroundColor || "white",
    width: fullWidth ? "100%" : width || "auto",
    maxWidth: maxWidth || "none",
    border: `${backgroundColor === "#E7EFF9" ? "2px" : "1px"} solid ${color}`,
    borderRadius:
      backgroundColor === "#32B2AC" || backgroundColor === "#FFF"
        ? "37px"
        : "10px",
    padding: isLoading ? "10px" : "17px 89px",
    textTransform: isCapitalize ? "none" : "Capitalize",
    fontWeight: "bold",
    fontSize: fontSize,
    opacity: disabled ? 0.5 : 1,
  };

  return (
    <Button
      style={buttonStyle}
      sx={icon ? { marginRight: "0px !important" } : ""}
      onClick={onClick}
      className={className}
      disabled={disabled}
      {...restProps}

    >
      {icon && title ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: { xs: "12px", md: "20px" },
            alignItems: "center",
          }}
        >
          <img src={icon} alt="icon" />
          {isLoading && <CircularProgress sx={{ color: color || "white" }} />}
          {title}
        </Box>
      ) : icon ? (
        <img src={icon} alt="icon" className={clearSearchData ? "clear-data-close-icon-search" : 'icon'} />
      ) : iconJsx ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: fullWidth ? "100%" : width || "auto",
          }}
        >
          <Typography mr={3} fontWeight={"bold"}>
            {title}
          </Typography>
          {isLoading && <CircularProgress sx={{ color: color || "white" }} />}
          {iconJsx}
        </Box>
      ) : (
        <>
          {isLoading && <CircularProgress sx={{ color: color || "white" }} />}
          {title}
        </>
      )}
    </Button>
  );
};
