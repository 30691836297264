import React, { useContext, useEffect } from "react";
import { Typography, Box, Link } from "@mui/material";
import { CustomButton } from "../../Components/Common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import { ExploreContext } from "../../App";

const success = require("../../assets/images/successTickFilled.svg").default;
export default function BookingSuccess({
  handleBack,
  bookingType,
  setPage,
  setSelectedOption,
  orderPlaced,
}) {
  const navigate = useNavigate();
  const { setIsOrderSuccess } = useContext(ExploreContext);

  useEffect(() => {
    return () => {
      setIsOrderSuccess(false)
    }
  }, [])

  function clearData() {
    setSelectedOption({
      dog: {},
      serviceName: {},
      fromDate: "",
      sortKey: "",
      categoryName: {},
      roomName: {},
      notes: "",
      trainerAvailability: [],
      trainerName: "",
      trainerId: "",
      trainerProfileImage: "",
      startTime: "",
      endTime: "",
      creditsAvailiable: true,
      creditsValue: {},
      cardName: "",
      date: "",
      cardNumber: "",
      cvv: "",
    });
    setPage(0);
  }

  return (
    <Box className="booking-success" px={{ xs: 0, sm: "34%" }} pt={{ xs: 3, sm: "10%" }}>
      <Box className="password-success">
        <img src={success} alt="success" />
        <Typography variant="body1" className="forgot-description">
          {orderPlaced
            ? "Congratulations! Your payment is successfully completed."
            : "Congratulations! Your booking is successfully confirmed."}
        </Typography>
        <CustomButton
          className="book-btn"
          title={orderPlaced ? "Purchase Another Item" : "Book Another"}
          color="#fff"
          backgroundColor="#32B2AC"
          iconJsx={<ChevronRightIcon />}
          fullWidth
          onClick={() => window?.location?.reload()}
        />
        <Link
          className="link-line"
          onClick={() => {
            // orderPlaced ? navigate("/") : handleBack();
            navigate("/")
          }}
        >
          Back To Home
        </Link>
      </Box>
    </Box>
  );
}
