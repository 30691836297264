import React, { useState, useRef } from "react";
import {
  Dialog,
  FormHelperText,
  Grid,
  Typography,
  Box,
  Link,
  IconButton,
} from "@mui/material";

import SignaturePad from "react-signature-canvas";
import { useEffect } from "react";
import { handleSave } from "../../pages/Shop/Functions/CartFunctions";
import { getLocalData } from "../../Utils";
import { CustomButton, CustomLoader } from "../Common";
import CloseIcon from "@mui/icons-material/Close";

const ContractSignature = ({ open, signature, setSignature, handleClose }) => {
  const signatureCanvasRef = useRef(null);
  const locationName = getLocalData("locationName");
  const [errors, setErrors] = useState({
    signatureCanvas: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrors({
      ...errors,
      signatureCanvas: "",
    });
  }, [handleClose]);

  const handleSignature = () => {
    if (signature?.value) {
      setSignature({ ...signature, value: "" });
    } else {
      signatureCanvasRef.current.clear();
    }
  };

  return (
    <>
      <CustomLoader IsLoading={loading} />
      <Dialog
        className="contract-signature-dialog"
        open={open}
        onClose={handleClose}
        sx={{ borderRadius: "20px!important" }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            width: "0.8em",
            height: "0.8em",
            right: 10,
            zIndex: 1,
            color: "red",
            borderRadius: "100%",
            padding: '0.8rem',
            border: "0.1rem solid red",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          className="signature-pad-box"
          container
          item
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            padding: 2,
            gap: 1.5,
          }}
        >
          <Typography className="heading" fontSize="1.7rem!important">
            {" "}
            Sign the Terms
          </Typography>
          <Box padding={2}>
            {signature?.value ? (
              <img
                src={signature?.value}
                alt="my signature"
                style={{
                  height: "130px",
                  width: "100%",
                  border: "2px solid rgba(0, 48, 135, 0.5)",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <SignaturePad
                canvasProps={{
                  className: "signatureCanvas",
                  alt: "Sign Here",
                  style: {
                    height: "130px",
                    width: "100%",
                    border: "2px solid rgba(0, 48, 135, 0.5)",
                    borderRadius: "10px",
                  },
                }}
                penColor={"#003087"}
                backgroundColor={"#fff"}
                ref={signatureCanvasRef}
              />
            )}
            {errors?.signatureCanvas && (
              <FormHelperText sx={{ color: "red" }}>
                {errors?.signatureCanvas}
              </FormHelperText>
            )}
          </Box>

          <Link
            color="#003087"
            fontSize="1rem"
            fontWeight="bold"
            component="button"
            variant="body2"
            onClick={handleSignature}
          >
            Clear
          </Link>
        </Grid>

        <Box sx={{ padding: "0 2rem 2rem 2rem" }}>
          <CustomButton
            backgroundColor="#003087"
            title={"Save"}
            fullWidth
            type="submit"
            disabled={signature?.value}
            onClick={() =>
              handleSave({
                signatureCanvasRef,
                setSignature,
                setLoading,
                errors,
                setErrors,
                locationName,
              })
            }
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ContractSignature;
