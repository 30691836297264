import * as React from "react";
import { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { clearLocalData, getLocalData } from "../../Utils";
import { ExploreContext } from "../../App";
import { Box, Divider, Slide, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function RegisterPop({ open, setOpen, messege }) {
  const redirectionRoute = getLocalData("redirectionRoute")
  const navigate = useNavigate()
  const { setIsRegister } = useContext(ExploreContext);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="signup_popup"
      >
        <DialogTitle className="alert-title text-center title signup_location_popup_label">
          Register Yourself.
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="signup_location_popup_label">
                {messege}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17  no-hover"
            onClick={handleClose}
            sx={{ color: "rgb(209, 58, 0)" }}
          >
            Skip
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            sx={{ color: "#003087" }}
            onClick={() => {
              setIsRegister(false);
              !redirectionRoute && clearLocalData();
              localStorage.setItem("NavigteToRegister", true);
              navigate("/account")
              window.location.reload();
            }}
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
