import React from "react";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Divider,
  Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const ErrorMessage = ({ message, setShowMessage, setActive }) => {
  const [openPopup, setOpenPopup] = React.useState(true);

  const handleClosePopup = () => {
    setShowMessage(false);
    setOpenPopup(false);
    // setMultipleFile([])
    if (setActive) {
      setActive(0);
    }
  };

  return (
    <>
      <Dialog
        className="global-modal-main-success"
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup("Cancel")}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">{message}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="blue-btn"
            style={{
              fontSize: "17px",
              color: "#003087",
              textTransform: "capitalize",
              fontWeight: "800",
            }}
            onClick={() => handleClosePopup()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
