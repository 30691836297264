import { API_URL_2, API_URL_3, OPEN_API_URL } from "../../Utils/constants";
import { ApiServices } from "../ApiServices";
import { getLocalData } from "../../Utils";

const Base_URL = API_URL_2;
const openAPI = OPEN_API_URL;

const locationId = encodeURIComponent(getLocalData("locationId"));
const clientId = encodeURIComponent(getLocalData("clientId"));

export const CheckClientDetail = async (clientId, locationId) => {
  const encodedClientId = encodeURIComponent(clientId);
  try {
    return await ApiServices.get(
      `${openAPI}client/getClientDetails?locationId=${locationId}&clientId=${encodedClientId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const UpdateClientDetail = async (payload, clientId) => {
  const encodedClientId = encodeURIComponent(clientId);
  try {
    return await ApiServices.put(
      `${Base_URL}client/updateClientDetails?id=${encodedClientId}`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const getLiabilityviewerContent = async () => {
  try {
    return await ApiServices.get(`${Base_URL}liability-waiver/recent`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetDogDetail = async (clientId, childId) => {
  const encodedClientId = encodeURIComponent(clientId);
  const encodedChildId = encodeURIComponent(childId);
  try {
    return await ApiServices.get(
      `${Base_URL}client/getChildDetails?childId=${encodedChildId}&clientId=${encodedClientId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const CreateDogProfile = async (data) => {
  try {
    let formData = new FormData();
    formData.append("clientId", data?.clientId);
    formData.append("locationId", getLocalData("locationId"));
    formData.append("locationName", getLocalData("locationName"));
    formData.append("franchiseeId", getLocalData("franchiseeId"));
    formData.append("franchiseeName", getLocalData("franchiseeName"));
    formData.append("profileImage", data?.profileImage);
    formData.append("firstName", data?.firstName);
    formData.append("lastName", data?.lastName);
    formData.append("gender", data?.gender);
    formData.append("breed", data?.breed);
    formData.append("birthDate", data?.birthDate);
    formData.append("strangers", data?.strangers);
    formData.append("possessive", data?.possessive);
    formData.append("biting", data?.biting);
    formData.append("barking", data?.barking);
    formData.append("advanced", data?.advanced);
    formData.append("acquisitionSource", data?.acquisitionSource);
    formData.append("yourDog", data?.notes);
    formData.append("veterinarian", data?.veterinarian);
    // formData.append("allergies", data?.allergies);
    formData.append("social", data?.social);
    formData.append("rabiesExpiration", data?.rabiesExpiration);
    formData.append("bordetellaExpiration", data?.bordetellaExpiration);
    formData.append("dhppExpiration", data?.dhppExpiration);
    formData.append("createdBy", data?.createdBy || 1000);
    formData.append("status", data?.status);

    const response = await ApiServices.post(
      `${openAPI}client/addChild`,
      formData
    );

    return response;
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const UpdateDogProfile = async (payload, petId) => {
  const encodedPetId = encodeURIComponent(petId);
  try {
    return await ApiServices.put(
      `${Base_URL}client/updateChild?id=${encodedPetId}`,
      payload
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const DeleteClientProfile = async (payload) => {
  try {
    return await ApiServices.post(`${Base_URL}client/disableClient`, payload);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllBreedList = async () => {
  try {
    return await ApiServices.get(`${Base_URL}breed-list`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllReferralSources = async (id) => {
  let franchiseeId = encodeURIComponent(id);
  try {
    return await ApiServices.get(
      `${API_URL_3}client/getReferralSource?franchiseeId=${franchiseeId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetFilteredSourceClients = async (searchParam) => {
  try {
    return await ApiServices.get(
      `${API_URL_3}client/getClientsBySearch?searchText=${searchParam}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllAcquisition = async () => {
  try {
    return await ApiServices.get(`${Base_URL}breed-list/getAllAcquisition`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const GetAllAllergies = async () => {
  try {
    return await ApiServices.get(`${Base_URL}breed-list/getAllAllergies`);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
// https://ifcxqbb98k.execute-api.us-east-1.amazonaws.com/dev/trophy/getAllTrophyByclientMob?clientId=
export const getClientTrophies = async () => {
  try {
    return await ApiServices.get(
      `${API_URL_3}trophy/getAllTrophyByclient?clientId=${clientId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getClientEarnedTrophies = async (clientId) => {
  try {
    return await ApiServices.get(
      `${API_URL_3}trophy/getAllTrophyByclientMob?clientId=${clientId}`
    );
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const AddProfileDocuments = async (obj) => {
  try {
    return await ApiServices.post(`${Base_URL}client/addDocumentProfile`, obj);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetReferralSourcesOptions = async (obj) => {
  try {
    return await ApiServices.post(`${Base_URL}referral-source`, obj);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetAllStates = async (obj) => {
  try {
    return await ApiServices.post(`${Base_URL}locations/getAllStates`, obj);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
export const GetAllLocations = async (obj) => {
  try {
    return await ApiServices.post(`${openAPI}locations`, obj);
  } catch (error) {
    console.error(error.message);
    return error;
  }
};
