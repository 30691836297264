import { uploadSignature } from "../../../Services/APIs/shop";
import { getFileFromUrl, setLocalData } from "../../../Utils";

const handleSave = async ({
    signatureCanvasRef,
    setSignature,
    setLoading,
    errors,
    setErrors,
    locationName,
  }) => {
    const isEmpty = signatureCanvasRef.current.isEmpty();
  
    if (isEmpty) {
      setErrors({
        ...errors,
        signatureCanvas: "Signature is required",
      });
      return;
    }
    setErrors({
      ...errors,
      signatureCanvas: "",
    });
  
    const signatureBlob = await getFileFromUrl(
      signatureCanvasRef.current.getTrimmedCanvas().toDataURL("image/png")
    );
  
    const formData = new FormData();
    formData.append("signatureImage", signatureBlob);
    uploadImage(formData, setSignature, setLoading, locationName);
  };
  
  const uploadImage = async (formData, setSignature, setLoading, locationName) => {
      setLoading(true);
    uploadSignature(formData, locationName).then((response) => {
      setSignature({
        open: false,
        value: response?.data?.url || "",
      });
      setLocalData('signatureImage', response?.data?.url)
      setLoading(false);
    });
  };

  export {
    handleSave,
    uploadImage
  }