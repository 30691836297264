import "./assets/fonts/fonts.css";
import "./App.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { createContext } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { GetClientDetailByEmailId } from "./Services/APIs";
import { clearLocalData, getLocalData, setLocalData } from "./Utils";
import { ErrorMessage } from "./Components/Modals/ErrorMessage.js";
import ExploreDetails from "./pages/Explore/ExploreDetails.jsx";

const withSuspense = (Component) => (props) =>
(
  <Suspense
    fallback={
      <Box className="progress-wrap">
        <Box className="loader">
          {" "}
          <CircularProgress size={60} />{" "}
        </Box>
      </Box>
    }
  >
    <Component {...props} />
  </Suspense>
);

const Sidebar = withSuspense(
  lazy(() => import("./pages/../Components/Sidebar/MainSideBar.jsx"))
);
const AuthenticationMain = withSuspense(lazy(() => import("./pages/Accounts")));
const Dashboard = withSuspense(lazy(() => import("./pages/Dashboard-Home")));
const Appointment = withSuspense(lazy(() => import("./pages/Appointments")));
const Explore = withSuspense(lazy(() => import("./pages/Explore")));
const Shop = withSuspense(lazy(() => import("./pages/Shop")));
const ShopAllProductsAndServices = withSuspense(
  lazy(() => import("./pages/Shop/PricingOptions"))
);
const ShopAllBundles = withSuspense(
  lazy(() => import("./pages/Shop/Bundles"))
);
const ShopAllContracts = withSuspense(
  lazy(() => import("./pages/Shop/Contracts"))
);
const Achievements = withSuspense(lazy(() => import("./pages/Achievements")));
const Profile = withSuspense(lazy(() => import("./pages/Profile")));
const Settings = withSuspense(lazy(() => import("./pages/Settings")));

const Layout = ({ loggedIn, Component, name, path, clientDetail, isGuest }) => {
  return (
    <>
      {loggedIn || isGuest ? (
        <>
          <Sidebar
            name={name}
            Component={Component}
            path={path}
            clientDetail={clientDetail}
          />
        </>
      ) : (
        Component
      )}
    </>
  );
};

export const ExploreContext = createContext({});

export const LocationDataContext = createContext({});

function App() {
  let token = getLocalData("token");
  let redirectionRoute = getLocalData('redirectionRoute')
  const [isGuest, setIsGuest] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [loggedIn, setLoggedIn] = useState(!!token);
  const [isExplore, setIsExplore] = useState(false);
  const [clientDetail, setClientDetail] = useState("");
  const [loader, setLoader] = useState(false);
  const [locationPopup, setLocationPopup] = useState(false);
  const [globalLocationId, setGlobalLocationId] = useState("");
  const [globalClientId, setGlobalClientId] = useState("");
  const [devCustomerId, setDevClientId] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOrderSuccess, setIsOrderSuccess] = useState(false);
  const [checkoutZipCode, setCheckoutZipCode] = useState('')

  let register = getLocalData("NavigteToRegister")
  const handleLogin = (username) => {
    setLocalData("token", username?.accessToken);
    setLocalData("user_detail", JSON.stringify(username?.userDetails));
    setLocalData("refreshToken", username?.refreshToken || '');
    callClientDetail(username?.userDetails);
    setLoggedIn(true);
  };

  const callClientDetail = (username) => {
    if (!username || !username?.email) return;
    setLoader(true);
    GetClientDetailByEmailId(username?.email).then((response) => {
      if (
        response?.statusCode === 200 &&
        response?.message === "No client found with the given email id"
      ) {
        clearLocalData();
        setLoader(false);
      } else if (response.statusCode === 200) {
        const [detail] = response?.data?.Items;
        setLocalData("locationId", detail?.locationId);
        if (detail?.locationName && detail?.franchiseeId && detail?.franchiseeName) {
          setLocalData("locationName", detail?.locationName);
          setLocalData("franchiseeId", detail?.franchiseeId);
          setLocalData("franchiseeName", detail?.franchiseeName);
        }
        setGlobalLocationId(encodeURIComponent(detail?.locationId));
        setLocalData("clientId", detail?.sortKey);
        setLocalData("clientName", detail?.firstName);
        setLocalData("cognitoId", detail?.cognitoId);
        setDevClientId(detail?.squareDeveloperCustomerId);
        setGlobalClientId(detail?.sortKey);
        setClientDetail(detail);
        if (response?.data?.Items[0]?.partitionKey === "LOC#1") {
          setLocationPopup(true);
        }
        if (!detail) {
          setShowMessage(true);
          setErrorMessage("No client found with the given email id");
          setTimeout(() => {
            setShowMessage(false);
            setLoggedIn(false);
          }, 1000);
        }
        setLoader(false);
      } else {
        setLoggedIn(false);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    let token = getLocalData("token");
    if (token) {
      setLoggedIn(true);
      let userDetail = JSON?.parse(getLocalData("user_detail"));
      callClientDetail(userDetail);
    } else {
      setLoggedIn(false);
    }
  }, [token]);

  useEffect(() => {
    let guestKey = getLocalData("isGuest");
    if (guestKey && !register) {
      setLoggedIn(true);
      setIsGuest(true);
    }
  }, [token]);

  useEffect(() => {
    let locationName = getLocalData("locationName");
    let LocationId = getLocalData('locationId')
    if (isGuest && !locationName && !LocationId) {
      setLocationPopup(true);
    }
  }, [isGuest]);

  useEffect(() => {
    if (token) {
      setIsGuest(false);
      setLocalData("isGuest", false)
    }
  }, [token]);

  return (
    <>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <ExploreContext.Provider
        value={{
          isExplore,
          setIsExplore,
          isGuest,
          setIsGuest,
          isRegister,
          setIsRegister,
          isOrderSuccess,
          setIsOrderSuccess,
          checkoutZipCode,
          setCheckoutZipCode
        }}
      >
        <LocationDataContext.Provider
          value={{
            globalLocationId,
            setGlobalLocationId,
            globalClientId,
            setGlobalClientId,
            devCustomerId,
            isGuest,
            setIsGuest,
            isRegister,
            setIsRegister,
          }}
        >
          <Router>
            <Routes>
              <Route
                path="/account"
                element={
                  loggedIn || isGuest ?
                    redirectionRoute && register ?
                      <Navigate to={redirectionRoute} />
                      :
                      (
                        <Navigate to="/" />
                      ) : (
                      <Layout
                        loggedIn={loggedIn}
                        isGuest={isGuest}
                        name="Authentication"
                        clientDetail={clientDetail}
                        Component={
                          <AuthenticationMain
                            onLogin={handleLogin}
                            locationPopup={locationPopup}
                            setLocationPopup={setLocationPopup}
                          />
                        }
                      />
                    )
                }
              />
              {/* Dashboard route */}
              <Route
                path="/"
                element={
                  loggedIn || isGuest ?
                    (
                      <Layout
                        loggedIn={loggedIn}
                        isGuest={isGuest}
                        name="Home"
                        clientDetail={clientDetail}
                        Component={
                          <Dashboard
                            loader={loader}
                            setLoader={setLoader}
                            clientDetail={clientDetail}
                            locationPopup={locationPopup}
                            setLocationPopup={setLocationPopup}
                            updateClientDataOnSetLocation={callClientDetail}
                          />
                        }
                      />
                    ) : (
                      <Navigate to="/account" />
                    )
                }
              />
              {/* Appointment route */}
              <Route
                path="/appointment"
                element={
                  loggedIn || isGuest ? (
                    <Layout
                      loggedIn={loggedIn}
                      isGuest={isGuest}
                      clientDetail={clientDetail}
                      name="Schedule a visit"
                      Component={<Appointment clientDetail={clientDetail} />}
                    />
                  ) :
                    <Navigate to="/" />
                }
              />

              {/* explore route */}
              <Route
                path="/explore"
                element={
                  // loggedIn || isGuest ? (
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    clientDetail={clientDetail}
                    name="Schedule a visit"
                    Component={<Explore clientDetail={clientDetail} />}
                  />
                  // ) :
                  //   <Navigate to="/" />
                }
              />
              <Route
                path="/explore/explore-details"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    clientDetail={clientDetail}
                    name="Schedule a visit"
                    Component={<ExploreDetails clientDetail={clientDetail} />}
                  />
                }
              />

              {/* Pricing Option route */}
              <Route
                path="/shop/pricing-option"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    clientDetail={clientDetail}
                    name="Shop"
                    Component={
                      <ShopAllProductsAndServices clientDetail={clientDetail} />
                    }
                  />
                }
              />
               {/* Bundles route */}
               <Route
                path="/shop/bundle"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    clientDetail={clientDetail}
                    name="Shop"
                    Component={
                      <ShopAllBundles clientDetail={clientDetail} />
                    }
                  />
                }
              />

              {/* Contracts route */}
              <Route
                path="/shop/contract"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    clientDetail={clientDetail}
                    name="Contract"
                    Component={
                      <ShopAllContracts clientDetail={clientDetail} />
                    }
                  />
                }
              />
              <Route
                path="/shop"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    name="Shop"
                    clientDetail={clientDetail}
                    Component={<Shop clientDetail={clientDetail} />}
                  />
                }
              />
              <Route
                path="/profile"
                element={
                  loggedIn ? (
                    <Layout
                      loggedIn={loggedIn}
                      isGuest={isGuest}
                      name="Profile"
                      clientDetail={clientDetail}
                      Component={
                        <Profile
                          clientDetail={clientDetail}
                          callClientDetail={callClientDetail}
                          setLoggedIn={setLoggedIn}
                        />
                      }
                    />
                  ) : (
                    <Navigate to="/account" />
                  )
                }
              />
              <Route
                path="/trophy"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    isGuest={isGuest}
                    name="Trophy Shelf"
                    clientDetail={clientDetail}
                    Component={<Achievements clientDetail={clientDetail} />}
                  />
                }
              />
              <Route
                path="/settings"
                element={
                  <Layout
                    loggedIn={loggedIn}
                    clientDetail={clientDetail}
                    isGuest={isGuest}
                    name="Settings"
                    Component={<Settings clientDetail={clientDetail} />}
                  />
                }
              />
              {/* Default route */}
              <Route
                path="*"
                element={
                  <Navigate to={loggedIn || isGuest ? "/" : "/account"} />
                }
              />
            </Routes>
          </Router>
        </LocationDataContext.Provider>
      </ExploreContext.Provider>
    </>
  );
}

export default App;
