import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import DynamicSidebar from "../Sidebar/DynamicSidebar";
import LocationSidebar from "../Sidebar/LocationSidebar";
import { CustomButton } from "./CustomButton";
import { LocationDataContext } from "../../App";
import { getLocalData, setLocalData } from "../../Utils";

const location = require("../../assets/images/location.svg").default;

export function Header({
  clientDetail,
  updateClientDataOnSetLocation,
  setActiveSteps,
  appointmentOptions,
  IsError,
  error,
  handleSelectedItems,
  setIsYourSchedule,
}) {
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState("");
  const [locationName, setLocationName] = useState("");
  const udatedLocation = getLocalData("locationName") || "";
  const { globalLocationId, isGuest } = useContext(LocationDataContext);
  const clientId = getLocalData("clientId");

  useEffect(() => {
    setLocationName(udatedLocation);
  }, [udatedLocation, clientId]);

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setTimeOfDay("Morning");
    } else if (currentHour >= 12 && currentHour < 17) {
      setTimeOfDay("Afternoon");
    } else {
      setTimeOfDay("Evening");
    }

    setLocationName(clientDetail?.locationName);
  }, []);

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  useEffect(() => {
    if (globalLocationId && globalLocationId?.locationId) {
      setLocationName(globalLocationId?.locationName);
      setLocalData("locationId", globalLocationId?.locationId);
    }
  }, [globalLocationId]);

  return (
    <Box className="top-header" py={{ xs: 1, md: 2 }}>
      <Typography className="header-text">
        Good {timeOfDay}{" "}
        <span className="">
          {isGuest
            ? "Guest"
            : `${capitalizeFirstLetter(clientDetail?.firstName) || ""}  ${
                capitalizeFirstLetter(clientDetail?.lastName) || ""
              }`}
          !
        </span>
      </Typography>
      {clientDetail || isGuest ? (
        <CustomButton
          onClick={() => {
            setNotifyOpen(true);
          }}
          className="header-btn"
          title={
            (locationName || clientDetail?.locationName || udatedLocation) ===
            "undefined"
              ? "Location"
              : locationName || clientDetail?.locationName || udatedLocation
          }
          color="#fff"
          icon={location}
          backgroundColor="#32B2AC"
        />
      ) : (
        ""
      )}
      <DynamicSidebar
        open={notifyOpen}
        handleClose={() => setNotifyOpen(false)}
        Component={
          <LocationSidebar
            handleClose={() => setNotifyOpen(false)}
            clientDetail={clientDetail}
            updateClientDataOnSetLocation={updateClientDataOnSetLocation}
            setActiveSteps={setActiveSteps}
            appointmentOptions={appointmentOptions}
            IsError={IsError}
            error={error}
            handleSelectedItems={handleSelectedItems}
            setIsYourSchedule={setIsYourSchedule}
          />
        }
      />
    </Box>
  );
}
